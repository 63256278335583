.layout {
  position: relative;
  min-height: 100vh;
  color: #ffffff;
}

.main {
  padding-top: 100px;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.glassyPanel {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 40px;
  background: rgba(40, 40, 40, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .main {
    padding-top: 120px;
  }

  .glassyPanel {
    padding: 30px;
  }
}
