.headerLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #928374;
  transition: color 0.3s ease;
}

.headerLink:hover {
  color: #ffffff;
}

.icon {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.text {
  font-family: 'Inknut Antiqua', serif;
}

.large .text {
  font-size: 18px;
}

.medium .text {
  font-size: 16px;
}

.small .text {
  font-size: 14px;
}

.large .icon {
  height: 28px;
  width: 28px;
}

.small .icon {
  height: 20px;
  width: 20px;
}
