@import url('https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #ffffff;
  background-color: #24221E;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

.fade-effect {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.fade-effect.visible {
  opacity: 1;
}

