.projectCard {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.projectCard h3 {
  font-family: 'Inknut Antiqua', serif;
  color: #928374;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.projectCard p {
  color: #cccccc;
  margin-bottom: 15px;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.tech {
  background: rgba(146, 131, 116, 0.2);
  color: #cccccc;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
}

.projectCard a {
  display: inline-block;
  background: #928374;
  color: #1c1c1c;
  padding: 8px 15px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.projectCard a:hover {
  background: #aaaaaa;
}
