.projectsContent h2 {
  font-family: 'Inknut Antiqua', serif;
  color: #cccccc;
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-align: center;
}

.projectsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

@media (max-width: 768px) {
  .projectsGrid {
    grid-template-columns: 1fr;
  }
}
