.homeContent {
  text-align: center;
}

.homeContent h2 {
  font-family: 'Inknut Antiqua', serif;
  color: #cccccc;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.homeContent p {
  color: #928374;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto 30px;
  line-height: 1.6;
}
 
.contactButton {
  display: inline-block;
  background: #928374;
  color: #1c1c1c;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin: 30px;
}

.contactButton:hover {
  background: #aaaaaa;
  transform: translateY(-2px);
}

.contactButton:active {
  transform: translateY(0);
}

