.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 20px;
    background-color: rgba(40, 40, 40, 0.2);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoContainer {
    flex: 1;
}

.nav {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.githubContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
    }

    .logoContainer, .nav, .githubContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .githubContainer {
        margin-bottom: 0;
    }
}
